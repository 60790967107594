<template>
  <div>
    Redirecting...
  </div>
</template>

<script>
export default {
  mounted() {
    this.$setLoader()

    if (this.$route.query.path) {
      let baseUrl = "insightzclub://"

      baseUrl += this.$route.query.path

      if (this.$route.query.pathId) {
        baseUrl += `/${this.$route.query.pathId}`
      }

      window.location.href = baseUrl
    } else {
      this.$router.push({ path: "/404" });
    }

  }
};
</script>
